import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'

function SEO ({description, lang, meta, title}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ].concat(meta)}
          >
            {/* <script src='https://cdn.snipcart.com/themes/v3.0.0-beta.3/default/snipcart.js' />
            <link rel='stylesheet' href='https://cdn.snipcart.com/themes/v3.0.0-beta.3/default/snipcart.css' /> */}
            {/* <script src='https://ajax.googleapis.com/ajax/libs/jquery/2.2.2/jquery.min.js' />
            <script src='https://cdn.snipcart.com/scripts/2.0/snipcart.js' id='snipcart' data-api-key='ZjllYTJiZWItNmI0Yy00YWZjLWEyYmYtYzk1MWZmNDExNjVkNjM2NzQ2OTA5MDAxMzA2OTQy' />
            <link href='https://cdn.snipcart.com/themes/2.0/base/snipcart.min.css' type='text/css' rel='stylesheet' /> */}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
