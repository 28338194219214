import React from 'react'
import {useCookies} from 'react-cookie'
import PortableText from '../portableText'

import styles from './alert.module.css'

export default function index ({message, alertCookieName, alertCookieMaxAge}) {
  const [cookies, setCookie] = useCookies([alertCookieName])
  const maxAge = alertCookieMaxAge ? alertCookieMaxAge * 24 * 60 * 60 : 5184000

  const closeAlert = () => {
    setCookie(alertCookieName, false, {path: '/', maxAge, sameSite: true})
  }
  console.log(cookies[alertCookieName])
  return (
    <>
      {cookies[alertCookieName] !== 'false' &&
      <div className={styles.alartBarWrapper}>
        <div className={styles.alertBarInnerWrapper}>
          <PortableText blocks={message} />
          <div className={styles.alertBarCloseWrapper}>
            <button className={styles.alertBarClose} type='button' onClick={closeAlert} aria-label='Close alert'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.695 24a1.383 1.383 0 01-1.284-.858 1.389 1.389 0 01.301-1.515L10.34 12 .712 2.372A1.389 1.389 0 112.678.407l10.61 10.61a1.389 1.389 0 010 1.965l-10.61 10.61a1.382 1.382 0 01-.983.408z' fill='#212121' /><path d='M22.305 0a1.384 1.384 0 011.284.858 1.388 1.388 0 01-.301 1.515L13.66 12l9.628 9.628a1.389 1.389 0 11-1.966 1.965l-10.61-10.61a1.389 1.389 0 010-1.965L21.323.408A1.383 1.383 0 0122.305 0z' fill='#212121' /></svg>
            </button>
          </div>
        </div>
      </div>
      }
    </>
  )
}
