import React, {useState} from 'react'
import fetch from 'node-fetch'
import {navigate} from 'gatsby-link'
import styles from './page.module.css'
export default function FranchiseForm (props) {
  // const [isChecked, setIsChecked] = useState(false)
  const [formData, setFormData] = useState({
    wax: '',
    sms: false,
    first_name: '',
    last_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    mobile: '',
    net_worth: '',
    liquidity: '',
    market: '',
    bot_field: ''
  })

  function handleCheckboxChange () {
    setFormData({...formData, sms: !formData.sms})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log({event})
    fetch(props.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(formData)
    })
    // .then(response => {
    //   console.log(response)
    //   // do something with the response
    //   setFormData({
    //     first_name: '',
    //     last_name: '',
    //     address: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     email: '',
    //     mobile: '',
    //     net_worth: '',
    //     liquidity: '',
    //     market: '',
    //     bot_field: ''
    //   })
    // })

      .then(() => navigate('/franchise-thanks'))
      .catch(error => {
        console.error(error)
      })
  }

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }
  if (!props.url) {
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <div hidden>
        <label>
          Don't fill this out:{' '}
          <input name={'bot_field'} onChange={handleChange} />
        </label>
      </div>

      {/* First Name */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'first_name'}>
          Your name
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='first_name' value={formData.first_name} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Last Name */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'last_name'}>
          Last Name
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='last_name' value={formData.last_name} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* E-mail */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'email'}>
          E-mail Address
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'email'}
            name='email' value={formData.email} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Mobile */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'mobile'}>
          Mobile
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'tel'}
            name='mobile' value={formData.mobile} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Address */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'address'}>
          Address
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='address' value={formData.address} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* City */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'city'}>
          City
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='city' value={formData.city} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* State */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'state'}>
          State
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='state' value={formData.state} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Zip */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'zip'}>
          Zip-Code
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='zip' value={formData.zip} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Market */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'market'}>
          Target Market of Interest (City)
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='market'
            value={formData.market}
            onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Would you rather */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'wax'}>
          Would you rather wax a gorilla or a porcupine?
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='wax' value={formData.wax} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Net Worth */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'net_worth'}>
          Approx Net Worth
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='net_worth' value={formData.net_worth} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Liquidity */}
      <div className='field'>
        <label className={styles.customLabel} htmlFor={'liquidity'}>
          Liquidity
        </label>
        <div className='control'>
          <input
            className={styles.customInput}
            type={'text'}
            name='liquidity' value={formData.liquidity} onChange={handleChange}
            required
          />
        </div>
      </div>

      {/* Consent */}
      <div className={styles.customCheckboxWrapper}>
        <label className={styles.customLabelCheckbox} htmlFor='sms'>
          I consent to receiving communication via text message from The Screamin Peach related to the franchise application process
        </label>
        <div className='control'>
          <input
            id='sms'
            className={styles.customCheckbox}
            type={'checkbox'}
            name='sms' value={formData.sms}
            // onChange={handleChange}
            defaultChecked={formData.sms}
            onClick={() => handleCheckboxChange()}

          />
        </div>
      </div>

      <div className='field'>
        <button disabled={!(formData.first_name && formData.last_name && formData.address && formData.city && formData.state && formData.zip && formData.email && formData.mobile && formData.net_worth && formData.liquidity && formData.market) || formData.bot_field} className={styles.customButton} type='submit'>Send</button>
      </div>

    </form>
  )
}
