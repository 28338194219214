// import React from 'react'
// import Img from 'gatsby-image'
// import {getFluidGatsbyImage} from 'gatsby-source-sanity'
// import clientConfig from '../../client-config'

// import './figure.module.css'

// export default ({node}) => {
//   console.log({node})
//   const fluidProps = getFluidGatsbyImage(
//     node.asset._ref,
//     {maxWidth: 890},
//     ...clientConfig.sanity
//   )
//   return (
//     <>
//       <figure>
//         <Img fluid={fluidProps} alt={node.alt} />
//         <figcaption>{node.caption}</figcaption>
//       </figure>
//     </>
//   )
// }
import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

import './figure.module.css'

export default ({node}) => {
  if (!node.asset) {
    return null
  }
  // console.log({node})
  // console.log({clientConfig})
  // const fluidProps = getFluidGatsbyImage(
  //   node.asset._ref,
  //   {maxWidth: 640},
  //   ...clientConfig.sanity
  // )
  const fluidProps = getFluidGatsbyImage(node.asset._ref, {maxWidth: 890}, clientConfig.sanity)

  return (
    <>
      <figure style={{maxWidth: '890px'}}>
        <Img fluid={fluidProps} alt={node.alt} />
        <figcaption>{node.caption}</figcaption>
      </figure>
    </>
  )
}
