import React from 'react'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import './gallery.module.css'

function Gallery (props) {
  // console.log({props})
  return (
    <>

      {props.images.map(slide => (
        <img
          key={slide._key}
          src={imageUrlFor(buildImageObj(slide))
            .width(220)
            // .height(Math.floor((9 / 16) * 1200))
            // .fit('crop')
            .url()}
        />
      ))}

    </>
  )
}
export default Gallery
