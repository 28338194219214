import React from 'react'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

// https://www.npmjs.com/package/react-responsive-carousel
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './slideshow.module.css'

function Slideshow (props) {
  return (
    <>
      <Carousel showThumbs showStatus={false}>
        {props.slides.map(slide => (
          <img
            key={slide._key}
            src={imageUrlFor(buildImageObj(slide))
              .width(1400)
              .quality(90)
              .auto('format')
              // .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .url()}
          />
        ))}
      </Carousel>
    </>
  )
}
export default Slideshow
