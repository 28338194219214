import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'

import Image from 'gatsby-image'

import Helmet from 'react-helmet'
import {Location} from '@reach/router'
import Instagram from '../images/icons/instagram.svg'
import Facebook from '../images/icons/facebook.svg'
import Header from './header'
import AlertBar from '../components/AlertBar/index'
// import Navigation from './Navigation'

import '../styles/layout.css'
import styles from './layout.module.css'

const SiteideAlertBar = () => {
  const data = useStaticQuery(graphql`
    {
      alert: sanitySiteSettings {
        _rawAlert
      }
    }
  `)
  return <>{ data.alert._rawAlert && data.alert._rawAlert.alertEnabled === true && data.alert._rawAlert.alertCookieName && (
    <AlertBar message={data.alert._rawAlert.alertBody} alertCookieName={data.alert._rawAlert.alertCookieName.current} alertCookieMaxAge={data.alert._rawAlert.alertCookieMaxAge} />
  )}
  </>
}

const Layout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  mainImage,
  title
}) => {
  const wrapperStyles = {
    maxWidth: '1900px',
    width: '100%'
  }
  return (
    <>
      <SiteideAlertBar />
      {/* <div className={location.pathname === '/' ? styles.contentHome : styles.content}> */}
      <Helmet>
        <link key={`snipcartCSS`} rel='stylesheet' href='https://cdn.snipcart.com/themes/v3.0.12/default/snipcart.css' />
      </Helmet>
      <Location children={children}>
        {({location}) => {
          return (
            <div className={location.pathname.replace(/\//g, '') === '' ? 'contentHome' : location.pathname.replace(/\//g, '')}>
              {/*  <div className={styles.bookNow}> <Link to='/book-now'>Book Now</Link></div> */}
              <Header location={location.pathname} siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
              {mainImage && mainImage.asset && (
                <div className={styles.mainImage}>
                  <Image fixed={mainImage.asset.fixed} alt={title} style={wrapperStyles} />
                </div>
              )}

              <div>
                {[children]}
              </div>
            </div>
          )
        }}
      </Location>

      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>

          <div className={styles.siteInfo}>
            &copy; {new Date().getFullYear()} {siteTitle} All rights reserved. <Link to='/privacy'>Privacy</Link>
          </div>
          <div className={styles.footerSocial}>
            <a href='https://www.facebook.com/screaminpeach/' target='_blank' title='Facebook' rel='noopener noreferrer' aria-label='Facebook Link'><Facebook /></a>
            <a href='https://www.instagram.com/thescreaminpeach/' target='_blank' title='Instagram' rel='noopener noreferrer' aria-label='Instagram Link'><Instagram /></a>

          </div>
        </div>
      </footer>

    </>
  )
}

export default Layout
