import React from 'react'
import {Link} from 'gatsby'

import styles from './header.module.css'

const SubLinks = (props) => {
  return (
    <ul className='sublinks'>
      {props.links.map((sublinks) => (
        <li key={sublinks.siteLink}>

          {sublinks.siteLink.includes('https') ? (
            <a href={sublinks.siteLink} target='_blank' title={sublinks.title}>
              {sublinks.title}
            </a>
          ) : (
            <Link to={sublinks.siteLink} title={sublinks.title}>{sublinks.title} {sublinks.subTitle}</Link>
          )}
        </li>
      ))}
    </ul>
  )
}

function Navigation ({nav, main}) {
  // console.log({nav})
  return (
    <ul className={styles.topMainNav}>

      {nav.links.map((links, index) => (
        <li key={links._key}>

          {links.siteLink ? (
            <>
              {links.siteLink.includes('https') ? (
                <a href={links.siteLink} target='_blank' title={links.title}>
                  {links.title}
                </a>
              ) : (
                <Link to={links.siteLink} title={links.title}>{links.title}</Link>
              )}

            </>
          ) : (
            <>
              {links.title}
            </>
          )}

          {links.links && links.links.length
            ? <SubLinks links={links.links} />
            : ''}
        </li>
      ))}

    </ul>
  )
}
// {main ? (
//   <li><a href='#' className='snipcart-checkout'>Cart</a></li>
// ) : (
//   ''
// )}
export default Navigation
