import {Link, graphql, StaticQuery} from 'gatsby'
import React from 'react'

import Navigation from './Navigation'
import Icon from './icon'
import Logo from '../images/logo.svg'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({location, onHideNav, onShowNav, showNav, siteTitle}) => {
  return (
    <div className={location === '/' ? styles.rootHome : styles.root}>
      <div className={styles.wrapper} style={{alignItems: `center`}}>
        <div className={styles.branding}>
          <Link to='/' aria-label='Home page'><Logo /></Link>
        </div>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav} aria-label='Open Menu'>
          <Icon symbol='hamburger' />
        </button>

        <StaticQuery
          query={graphql`
            query sanityNav {
              sanityNavigation(_id: { eq: "d6b880a7-d618-4b78-8a4b-ec8f1c804e23" }) {
                links {
                  _key
                  title
                  subTitle
                  siteLink
                  links {
                    _key
                    title
                    subTitle
                    siteLink
                  }
                }
              }
            }
          `}
          render={data => (
            <nav className={cn(styles.nav, showNav && styles.showNav)}>
              <Navigation nav={data.sanityNavigation} main />
            </nav>
          )}
        />

      </div>
    </div>
  )
}

export default Header
